import * as React from "react"

export const TEPrice = (props) => {

    const teStyle = {}

    if (!props.id) {
        throw new Error("No id passed to TEPrice")
    }

    return (
        <>
            <div className="te" style={teStyle}>
                <div>$10.00 ({props.id})</div>
            </div>
        </>
    )

}