import * as React from "react"
import { Button } from "../shared/Button"

export const TEBuyButton = (props) => {

    const teStyle = {}

    if (!props.id) {
        throw new Error("No id passed to TEBuyButton")
    }

    return (
        <>
            <link rel="stylesheet" href="https://js.tktsrv.net/elements/0.0.2/global.css"></link>
            <div className="te" style={teStyle}>
                <Button
                    size={48}
                    variant="primary"
                   // onClick={serviceIncluded ? onRemoveCfarService : onClose}
                >
                    Buy {props.id} baby!
                </Button>
            </div>
        </>
    )

}