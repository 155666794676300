import * as React from "react";
import { createRoot, Root } from "react-dom/client";
import { TEPrice } from "./TEPrice";

const CUSTOM_ELEMENT_TAG = "te-price";

class TEPriceWebComponent extends HTMLElement {
  /**
   * `connectedCallback` is called to initialise the custom element
   */
  connectedCallback() {
    const container = document.createElement("div");
    this.attachShadow({ mode: "open" }).appendChild(container);

    this.root = createRoot(container);

    this.root.render(<TEPrice id="test" />);
  }

  render() {
    console.log("render called")
  }

}

export default TEPriceWebComponent;

window.customElements.get(CUSTOM_ELEMENT_TAG) ||
  window.customElements.define(
    CUSTOM_ELEMENT_TAG,
    TEPriceWebComponent
  );