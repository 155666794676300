import * as React from "react";
import classNames from "classnames";

const BUTTON_VARIANTS = {
  primary: "button--primary",
  outlined: "button--outlined",
  destructive: "button--destructive",
};

const BUTTON_SIZES = {
  32: "button--32",
  40: "button--40",
  48: "button--48",
};

export const Button = ({
  variant = "primary",
  size = 40,
  children,
  className,
  type = "button",
  ...nativeButtonProps
}) => (
  <button
    type={type}
    className={classNames(
      "button",
      BUTTON_VARIANTS[variant],
      BUTTON_SIZES[size],
      className
    )}
    {...nativeButtonProps}
  >
    {children}
  </button>
);