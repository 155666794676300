import * as React from "react";
import { createRoot, Root } from "react-dom/client";
import { TEBuyButton } from "./TEBuyButton";

const CUSTOM_ELEMENT_TAG = "te-buy-button";

class TEBuyButtonWebComponent extends HTMLElement {
  /**
   * `connectedCallback` is called to initialise the custom element
   */
  connectedCallback() {
    const container = document.createElement("div");
    this.attachShadow({ mode: "open" }).appendChild(container);

    this.root = createRoot(container);

    this.root.render(<TEBuyButton id="test" />);
  }
}

export default TEBuyButtonWebComponent;

window.customElements.get(CUSTOM_ELEMENT_TAG) ||
  window.customElements.define(
    CUSTOM_ELEMENT_TAG,
    TEBuyButtonWebComponent
  );